<template>
  <div>
    <el-form label-width="80px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="状态:">
            <el-select style="width:100%"
                       @change="initData"
                       v-model="form.status"
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="身份昵称:">
            <el-input clearable
                      placeholder="请输入"
                      v-model="form.role_name"
                      style="width:100%"
                      @keyup.enter.native="initData" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-button type="primary"
                     @click="initData">查询</el-button>
          <el-button type="primary"
                     @click="addRole">添加身份</el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- <el-table border
              :stripe="true"
              highlight-current-row
              :max-height="$store.state.tableHeight + 45"
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="id"
                       align="center"
                       width="100"
                       label="ID" />
      <el-table-column prop="role_name"
                       width="200"
                       align="center"
                       label="身份昵称" />
      <el-table-column prop="menu_names"
                       show-overflow-tooltip
                       label="权限" />
      <el-table-column prop="status"
                       align="center"
                       width="100"
                       label="状态" />
      <el-table-column align="center"
                       width="150"
                       label="操作">
        <template slot-scope="{row}">
          <span class="text_btn_primary"
                @click="editRow(row)">
            编辑
          </span>
          <span class="text_btn_danger"
                @click="delRow(row)">
            删除
          </span>
        </template>
      </el-table-column>
    </el-table> -->
    <Table ref="TablesRef"
           :tableData="tableData" />

    <Form ref="formRef" />

    <!-- <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" /> -->

  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      statusList: [{ value: 0, label: '不显示' },
      { value: 1, label: '显示' },
      { value: '', label: '全部' }],
      form: {
        status: '',
        role_name: ''
      },
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'settingUserAdmin',
      tableColumns: [
        { prop: 'id', align: 'center', label: 'ID', width: '100', showToopic: false },
        { prop: 'role_name', align: 'center', label: '身份昵称', width: '200', showToopic: false },
        { prop: 'menu_names', align: 'left', label: '权限', width: '', showToopic: true },
        { prop: 'status', align: 'center', label: '状态', width: '100', showToopic: false, isStatus: true },

        {
          prop: 'd', align: 'center', label: '操作', width: '150', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.initData()
    this.setTablesColums()

  },
  methods: {
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '开启' : '关闭'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v2/tc_power/role_lst',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list
      })
    },
    addRole () {
      let form = {
        role_name: '',
        menus: [1],
        role_desc: '',
        alias: '',
        status: 1
      }
      this.$refs.formRef.text = '新增'
      this.$refs.formRef.form = form
      this.$refs.formRef.getTree()
      this.$refs.formRef.dialogVisible = true
    },
    editRow (row) {
      let form = JSON.parse(JSON.stringify(row))
      form.menus = form.menus.split(',').map(item => item - 0)
      this.$refs.formRef.form = form
      this.$refs.formRef.text = '编辑'
      this.$refs.formRef.getTree()
      this.$refs.formRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/tc_power/del_role',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.initData()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>