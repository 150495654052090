<template>
  <div>
    <el-dialog :title="text+'身份'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               :validate-on-rule-change="false"
               ref="formRefs"
               label-width="120px">

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="身份名称"
                          prop="role_name">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.role_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="唯一标识"
                          prop="alias">
              <el-input clearable
                        placeholder="请输入"
                        :disabled="text != '新增'"
                        v-model.trim="form.alias"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.role_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="是否启用"
                          prop="status">
              <el-radio-group v-model="form.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10"
                class="tree_warp">
          <el-col :span="24">
            <el-form-item label="权限">
              <el-tree :props="props"
                       ref="treeRef"
                       :default-checked-keys="form.menus"
                       :data="treeList"
                       node-key="id"
                       show-checkbox />

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      form: {},
      rules: {
        role_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        alias: [
          { required: true, message: '请选择', trigger: 'blur' },
        ]
      },
      treeList: [],
      props: {
        label: 'menu_name',
        children: 'child',
        checked: false, indeterminate: true
      }
    }
  },
  methods: {
    handleClose () {
      this.form = {}
      this.$refs.formRefs.resetFields()
      this.dialogVisible = false
    },
    change (e, checked) {
      console.log(e, checked);
    },
    handleSave () {
      let form = JSON.parse(JSON.stringify(this.form))
      this.$nextTick(() => {
        form.menus = this.$refs.treeRef.getCheckedKeys().concat(this.$refs.treeRef.getHalfCheckedKeys()).join(',')
        // console.log('54564564564', this.$refs.treeRef.getHalfCheckedKeys());
        // return
        this.$refs.formRefs.validate(val => {
          if (!val) return
          this.$http({
            url: '/api/v2/tc_power/create_role',
            method: 'POST',
            data: form
          }).then(res => {
            this.$notify({
              title: '提示',
              message: '成功!',
              type: 'success'
            });
            this.$parent.initData()
            this.handleClose()
          })
        })
      })

    },
    getTree () {
      this.$http({
        url: '/api/v2/tc_power/lst',
        method: 'get',
      }).then(res => {
        this.treeList = res.data.list

        if (this.text != '新增') {
          let list = this.flatList(this.treeList)
          for (let index = 0; index < list.length; index++) {
            let idx = this.form.menus.indexOf(list[index].id)
            if (idx != -1 && list[index].child) {
              this.form.menus.splice(idx, 1)
            }
          }
        }
      })
    },
    flatList (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.flatList(list[i].child))
        }
      }
      return allList
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.tree_warp {
  height: 350px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>